import React, { useState, useEffect, useMemo } from 'react';
import '../pages/Dashboard.css';
import Dashboardtwo from '../pages/Dashboard-2';
import axios from 'axios';
import configration from '../config/config';
import Web3 from 'web3';
import { useMetaMask } from "metamask-react";
import DataTable from "react-data-table-component";
import { BsFillFileBarGraphFill, BsArrowRight } from 'react-icons/bs';
import { AiOutlineArrowDown } from 'react-icons/ai';
import xcoreABI from "./ABI/xcore.json";
import busdABI from "./ABI/busd.json";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from './vbit.png'
import $ from 'jquery'
import WithdrawTokenForm from './withdrawpage';
import AmountSettings from './settings';

$(document).ready(function () {

    // $('.tree li ul li a').click(function (e) {
    //   e.preventDefault();
    //   $(this).closest("li").find("[className^='ul_submenu']").slideToggle();
    // });
    $(function () {
        $('.first-level-tree-structure ul').hide();
        $('.vertical-tree>ul').show();
        $('.vertical-tree ul.active').show();
        $('.vertical-tree li').on('click', function (e) {
            var children = $(this).find('> ul');
            if (children.is(":visible")) children.hide('fast').removeclassName('active');
            else children.show('fast').addclassName('active');
            e.stopPropagation();
        });
    });

});



$(document).ready(function () {
    if ($(window).width() < 600) {       // if width is less than 600px
        $(".nav-link").click(function () {
            $("#mySidenav").css("width", "0px");
        });
    }
    else {
        // $('#v-pills-profile-tab').click(function () {
        //   location.reload();
        // });

    }

    $("#mobile-three-line-collapse").click(function () {
        $("#mySidenav").css("width", "250px");
    });
    $("#mobile-close-collapse").click(function () {
        $("#mySidenav").css("width", "0px");
    });
    $(".dashboard-profile-table-tabs-section-hide").hide();
    $(".details-button-navigation button").click(function () {
        $(".dashboard-profile-section-hide").hide();
        $(".dashboard-profile-table-tabs-section-hide").show();
        $("#mySidenav").css("width", "100px");
        $(".dashboard-main-section").css("margin-left", "100px");
        $(".dashboard-navbar-brand img").css("width", "50px");
        $(".dashboard-navbar-brand img").css("height", "50px");
        $("#mobile-close-arrow-left").hide();
        $(".dashboard-text-1").hide();
        $("#mobile-close-arrow-right").show();
    });
    $(".button-dashboard-table-back").click(function () {
        $(".dashboard-profile-section-hide").show();
        $(".dashboard-profile-table-tabs-section-hide").hide();
        $("#mySidenav").css("width", "250px");
        $(".dashboard-main-section").css("margin-left", "250px");
        $(".dashboard-navbar-brand img").css("width", "80px");
        $(".dashboard-navbar-brand img").css("height", "80px");
        $("#mobile-close-arrow-left").show();
        $(".dashboard-text-1").show();
        $("#mobile-close-arrow-right").hide();
    });



    $("#mobile-close-arrow-right").hide();
    $("#mobile-close-arrow-left").click(function () {
        $("#mySidenav").css("width", "100px");
        $(".dashboard-main-section").css("margin-left", "100px");
        $(".dashboard-navbar-brand img").css("width", "50px");
        $(".dashboard-navbar-brand img").css("height", "50px");
        $("#mobile-close-arrow-left").hide();
        $(".dashboard-text-1").hide();
        $("#mobile-close-arrow-right").show();
    });
    $("#mobile-close-arrow-right").click(function () {
        $("#mySidenav").css("width", "250px");
        $(".dashboard-main-section").css("margin-left", "250px");
        $(".dashboard-navbar-brand img").css("width", "80px");
        $(".dashboard-navbar-brand img").css("height", "80px");
        $("#mobile-close-arrow-left").show();
        $(".dashboard-text-1").show();
        $("#mobile-close-arrow-right").hide();
    });


    if ($('#mySidenav').css('width') === '100px') {
        $('#mySidenav').css('a');
    }
});

function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
            $('#imagePreview').css('background-image', 'url(' + e.target.result + ')');
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
        }
        reader.readAsDataURL(input.files[0]);
    }
}
$("#imageUpload").change(function () {
    readURL(this);
});


function Dashboard() {

    const logOut = () => {
        localStorage.clear();
        window.location.href = '/'
    }



    return (
        <div>


            <span id="mobile-three-line-collapse">&#9776;</span>

            <div className="dashboard-sidenav-section" id="mySidenav">


                <div className="d-flex flex-row">
                    <div className="ms-auto me-3">
                        <span id="mobile-close-collapse"><i className="bi bi-x-lg"></i></span>
                    </div>
                </div>
                {/* <div className="d-flex flex-row">
                    <div className="ms-auto">
                        <span id="mobile-close-arrow-left"><i className="bi bi-chevron-left"></i></span>
                        <span id="mobile-close-arrow-right"><i className="bi bi-chevron-right"></i></span>
                    </div>
                </div> */}
                <div className="d-flex flex-row">
                    <div className="mx-auto">
                        <a className="dashboard-navbar-brand" href="/"><img src={logo} alt="logo" /></a>
                    </div>
                </div>

                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                    <button className="nav-link active" id="v-pills-Dashboard-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Dashboard"
                        type="button" role="tab" aria-controls="v-pills-Dashboard" aria-selected="false"><i className="bi bi-boxes"></i>
                        <span className="dashboard-text-1">Dashboard</span></button>

                    <button className="nav-link" id="v-pills-Listen-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Listen"
                        type="button" role="tab" aria-controls="v-pills-Listen" aria-selected="false"><i
                            className="bi bi-journal-bookmark-fill"></i> <span className="dashboard-text-1">Withdraw</span></button>
                            
                             <button className="nav-link" id="v-pills-setting-tab" data-bs-toggle="pill" data-bs-target="#v-pills-setting"
                        type="button" role="tab" aria-controls="v-pills-Listen" aria-selected="false"><i
                            className="bi bi-journal-bookmark-fill"></i> <span className="dashboard-text-1">Settings</span></button>

                    <button className="nav-link" id="v-pills-signout-tab" data-bs-toggle="pill" data-bs-target="#v-pills-signout"
                        type="button" role="tab" aria-controls="v-pills-signout" aria-selected="false" onClick={logOut}><i
                            className="bi bi-box-arrow-in-right"></i> <span className="dashboard-text-1" >Signout</span></button>
                </div>

            </div>

            <div className="dashboard-main-section">
                <div className="tab-content" id="v-pills-tabContent">

                    <div className="tab-pane fade  show active" id="v-pills-Dashboard" role="tabpanel" aria-labelledby="v-pills-Dashboard-tab">
                        <Dashboardtwo />
                    </div>

                    <div className="tab-pane fade " id="v-pills-Listen" role="tabpanel"
                        aria-labelledby="v-pills-Listen-tab">
                        {/* <h1 classNameName='pb-3'>Listen</h1> */}
                        <WithdrawTokenForm/>
                            
                    </div>
                    <div className="tab-pane fade " id="v-pills-setting" role="tabpanel"
                        aria-labelledby="v-pills-Listen-tab">
                        {/* <h1 classNameName='pb-3'>Listen</h1> */}
                        {/* <Dashboard/> */}
                       <AmountSettings/>
                            
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;