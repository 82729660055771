import { makeApiRequest } from "../config/axiosService";

  export const getLoanHistory = async () => {
    try {
      let params = {
        url: `getHistory`,
        method: "GET"
      }
      let response = await makeApiRequest(params);
      console.log('response',response)
      return {
        status:response.status,
        message: response.message,
        data:response.data
      }
    } catch (error) {
      console.log('getLoanHistory error',error);
      return {
        status:false,
        message: "Error on server"
      }
    }
  }
  export const getLoanSearchHistory = async (searchQuery) => {
    try {
      console.log(searchQuery,"payLoad");
      // return false
      let params = {
        url: `/list?query=${searchQuery}`,
        method: "GET"
      }
      let response = await makeApiRequest(params);
      console.log('response',response)
      return {
        status:response.status,
        message: response.message,
        data:response.data,
        totalPages:response.totalPages,
        currentPage:response.currentPage,
        transactionHash:response.transactionHash
      }
    } catch (error) {
      console.log('getLoanHistory error',error);
      return {
        status:false,
        message: "Error on server"
      }
    }
  }

  export const setAdminFee = async(payload)=>{
    try {
      console.log(payload,'payload')
      let params = {
        url:`/setFee`,
        method: "POST",
        data:payload
      }
      let response = await makeApiRequest(params)
      console.log(response)
      return{
        status:response.status,
        message:response.message,
        data:response.data
      }
    } catch (error) {
      console.log(error)
      return{
        status:false,
        message:"error on server"
      }
    }
  }

  export const setPrivateKey = async(payload)=>{
    try {
      console.log(payload,'payload')
      let params = {
        url:`/setPrivateKey`,
        method: "POST",
        data:payload
      }
      let response = await makeApiRequest(params)
      console.log(response)
      return{
        status:response.status,
        message:response.message,
        data:response.data
      }
    } catch (error) {
      console.log(error)
      return{
        status:false,
        message:"error on server"
      }
    }
  }