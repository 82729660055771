import React, { useState, useEffect } from "react";
import axios from "axios";
import configration from "../config/config";
import Web3 from "web3";
import { useMetaMask } from "metamask-react";
import DataTable from "react-data-table-component";
import { BsFillFileBarGraphFill, BsArrowRight } from "react-icons/bs";
import { AiOutlineArrowDown } from "react-icons/ai";
import xcoreABI from "./ABI/xcore.json";
import busdABI from "./ABI/busd.json";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLoanHistory, getLoanSearchHistory } from "../backend_api/authapis";
import moment from "moment";
import config from "../config/config";
import aaveLogo from "./aave-logo.png";
import { Key } from "@mui/icons-material";
import { FormControl } from "react-bootstrap";
import {
  BiEditAlt,
  BiTrash,
  BiArchiveOut,
  BiSolidSkipNextCircle,
  BiSolidSkipPreviousCircle,
} from "react-icons/bi";
import vusdlogo from './bg/VUSD.png'
var web3js = new Web3("https://data-seed-prebsc-1-s3.binance.org:8545/");

function Dashboard() {
  const [loanHistoryData, setLoanHistoryData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalpage, setTotalpage] = useState(1);
  const [page] = useState(5);

  useEffect(() => {
    getSearch();
  }, [currentPage, page, searchQuery]);

  const columns = [
    {
      name: "Date",
      width: "200px",
      selector: (row) => {
        return (
          <div>{moment.utc(row.createdAt).format("YYYY-MM-DD hh:mm:ss")}</div>
        );
      },
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
    },
    {
      name:"UserAddress",
      selector:(row)=> row.userAddress,
    },
    {
      name: "Hash",
      selector: (row) => {
        const transactionHash = row.transactionHash;
        console.log(row)
        if (transactionHash) {
          return (
            <a
              href={configration.transactionHashUrl + transactionHash}
              target="_blank"
              className="blue-color"
            >
              {transactionHash.substring(0, 5) +
                "..." +
                transactionHash.substring(transactionHash.length - 4)}
            </a>
          );
        }
        return null; // Handle the case where transactionHash is undefined
      },
    },
    {
      name: "rewardHash",
      selector: (row) => {
        const transactionHash = row.rewardHash;
        console.log(row)
        if (transactionHash) {
          return (
            <a
              href={configration.bkcUrl + transactionHash}
              target="_blank"
              className="blue-color"
            >
              {transactionHash.substring(0, 5) +
                "..." +
                transactionHash.substring(transactionHash.length - 4)}
            </a>
          );
        }
        return "-"; // Handle the case where transactionHash is undefined
      },
    },
    {
      name: "Asset",
      selector: (row) => {
        return (
          <>
            <img
              className="new-trans-assest-image"
              src={vusdlogo}
              alt="logo"
            />{" "}
            VUSD
          </>
        );
      },
    },
    {
      name: "Protocol",
      selector: (row) => {
        return (
          <div>
            <img className="new-trans-assest-image" src={aaveLogo} alt="logo" />{" "}
            AAVE V2
          </div>
        );
      },
    },
  ];

  const data = loanHistoryData
    ? loanHistoryData.map((data, index) => ({
        sno: index + 1,
        time: moment.utc(data.createdAt).format("YYYY-MM-DD hh:mm:ss"),
        userAddress: data.userAddress,
        amount: data.amount,
        transactionHash:data.transactionHash,
        rewardHash:data.rewardHash
      }))
    : [];

  const getSearch = async () => {
    try {
      // const response = await axios.post(`${configration.BACKEND_URL}/list?query=${searchQuery}`);
      const response = await getLoanSearchHistory(searchQuery)
      console.log(response,'response')

      console.log('withoutquery',response.data);
  
      if (response.status === true) {
        setLoanHistoryData(response.data);
      } else {
        console.log("Something went wrong");
      }
    } catch (error) {
      console.error(error);
      // Handle the error, e.g., set an error state or display an error message to the user.
    }
  };

  const paginationOptions = {
    rowsPerPageText: false,
    rangeSeparatorText: "of",
    noRowsPerPage: true,
    selectAllRowsItem: false,
    selectAllRowsItemText: false,
  };

  return (
    <div>
      <div className="container py-4">
        <h3 className="fw-bold">ADMIN DASHBOARD</h3>
      </div>
      <div className="py-5 new-trans-page-design sidenav-column-section-2">
        <div className="search-bar">
          <input
            className="custom-dashinput"
            type="text"
            id="searchInput"
            placeholder="Search..."
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
          />
          <button
            className="custom-dashbutn"
            onClick={getSearch}
            id="searchButton"
          >
            Search
          </button>
        </div>
        <h4 className="mb-4">Transactions</h4>
        <div className="card">
          <div className="table-trans-height">
            <DataTable
          
              columns={columns}
              data={data}
              sortIcon={<AiOutlineArrowDown />}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[10, 20, 30]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
