var env = "live"
var configration = {};
if (env == "local") {
    configration = {
        EXPLORER_RPC: "https://testnet.bscscan.com/",
        RPC: "https://data-seed-prebsc-1-s3.binance.org:8545/",
        CHAIN_ID: "0x61", // 333
        CHAIN_NAME: "BSC_TESTNET",
        CURRENCY_SYMBOL: "BNB",
        CURRENCY_DECIMALS: 18, // Set this to the appropriate number of decimals for your currency
        XCORE_ADDRESS: "0x8e1C2083b05D00DaF025f4F3f08598552F13e6c9", 
        BUSD_ADDRESS:"0x1EaE2855cf10e7b56B523C89cFBb1F4CE6e3939e",
        BACKEND_URL : 'http://localhost:5000/admin', 
        localhostFrontend:'http://localhost:3000/', 
        transactionHashUrl: "https://goerli.etherscan.io/tx/",
        flashLoan_contractAddress:"0xc5E7a6b9df28b0a47212A83CC48ca2E2dFDe42A4",
        goerliRpcURL : "https://goerli.infura.io/v3/",
        chainID : "0x5",
        network: "5",
        chainName : "Goerli test network",
        symbol : "GoerliETH",
        blockExplorerUrls : "https://goerli.etherscan.io",
        UniswapV2Router2: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
        transactionHashUrl: "https://goerli.etherscan.io/tx/",
        infuraKey:"https://goerli.infura.io/v3/093cf180272947989f95a835644d5999",
        bkcUrl:"https://bkcscan.io/tx/"
        
    }
} 
else {
    configration = {
        EXPLORER_RPC: "https://testnet.bscscan.com/",
        RPC: "https://data-seed-prebsc-1-s3.binance.org:8545/",
        CHAIN_ID: "0x61", // 333
        CHAIN_NAME: "BSC_TESTNET",
        CURRENCY_SYMBOL: "BNB",
        CURRENCY_DECIMALS: 18, 
        XCORE_ADDRESS: "0x8e1C2083b05D00DaF025f4F3f08598552F13e6c9", 
        BUSD_ADDRESS:"0x1EaE2855cf10e7b56B523C89cFBb1F4CE6e3939e", 
        BACKEND_URL : "https://api-flashloan.valobitfl.com/admin", // http://3.110.127.65:3000
        localhostFrontend:'https://adminpanel-flashloan.valobitfl.com/', //http://3.110.127.65:
        transactionHashUrl: "https://goerli.etherscan.io/tx/",
        flashLoan_contractAddress:"0xc5E7a6b9df28b0a47212A83CC48ca2E2dFDe42A4",
        goerliRpcURL : "https://goerli.infura.io/v3/",
        chainID : "0x5",
        network: "5",
        chainName : "Goerli test network",
        symbol : "GoerliETH",
        blockExplorerUrls : "https://goerli.etherscan.io",
        UniswapV2Router2: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
        transactionHashUrl: "https://goerli.etherscan.io/tx/",
        infuraKey:"https://goerli.infura.io/v3/093cf180272947989f95a835644d5999",
        bkcUrl:"https://bkcscan.io/tx/"
    }
}

// http://3.110.127.65:3001
// localhost: 'http://localhost:3001'

export default configration;