import axios from "axios";
import React, { useEffect, useState } from "react";
import configuration from "../config/config";
import { toast } from "react-toastify";
import Web3 from "web3";
import * as ethers from "ethers";
import abi from "./ABI/contractabi.json";
import { event } from "jquery";
import { setAdminFee, setPrivateKey } from "../backend_api/authapis";
function AmountSettings() {
  const [minAmount, setMinAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [currentMinAmount, setCurrentMinAmount] = useState("");
  const [currentMaxAmount, setCurrentMaxAmount] = useState("");
  const [newInputValue, setNewInputValue] = useState("");
  const [newPrivateKeyValue, setNewPrivateKeyValue] = useState("");


  const handleMinAmountChange = (event) => {
    setMinAmount(event.target.value);
  };

  const handleMaxAmountChange = (event) => {
    setMaxAmount(event.target.value);
  };

  const Saveamount = async () => {
    let maxAmt = Number(minAmount);
    let minAmt = Number(maxAmount);
    if (maxAmt > 0 && minAmt > 0) {
      try {
        // console.log("worked");
        // return false
        const payload = {
          maximumAmount: maxAmount,
          minimumAmount: minAmount,
        };
        const response = await axios.post(
          `${configuration.BACKEND_URL}/setAmount`,
          payload
        );
        // console.log(response.data, "save");
        toast.success("Amount Changed Successfully");
      } catch (error) {
        console.error("Error saving amount settings:", error);
        toast.error("Failed");
      }
    } else {
      return toast.error("please fill all the fields");
    }
  };

  const getAmount = async () => {
    try {
      const response = await axios.get(
        `${configuration.BACKEND_URL}/getAmount`
      );
      console.log(response.data, "response");
      if (
        response.data &&
        Array.isArray(response.data.data) &&
        response.data.data.length === 2
      ) {
        setCurrentMinAmount(response.data.data[0]);
        setCurrentMaxAmount(response.data.data[1]);
      } else {
        console.log("Invalid response data format");
      }
      console.log(currentMaxAmount, currentMinAmount);
    } catch (error) {
      console.log("Error fetching amount settings:", error);
    }
  };
  // useEffect(() => {
  //   getAmount();
  // }, []);

  const handleNewInputChange = (event) => {
    setNewInputValue(event.target.value);
  };

  const handleNewPrivateKeyChange = (event) =>{
    setNewPrivateKeyValue(event.target.value)
  }

  const submitPrivateKey = async() =>{
    try {
      if(newPrivateKeyValue){
        let payLoad = {
          privateKey : newPrivateKeyValue
        }
        let response = await setPrivateKey(payLoad);
        console.log("response",response);
        if(response){
        return toast.success("Added successfully");

        }
        console.log("response",response);
      }else{
        return toast.error("please fill the fields");
      }
    } catch (error) {
      console.log("submitPrivateKey error",error);
    }
  }
  const handleAddNewAmount = async () => {
    // event.preventDefault()
    try {
      // const web3 = new Web3(window.ethereum);
      // const provider = new ethers.BrowserProvider(window.ethereum);
      // const signer = await provider.getSigner();
      // const withdrawcontract = new ethers.Contract(
      //   configuration.flashLoan_contractAddress,
      //   abi,
      //   signer
      // );
      // const fee = newInputValue * 100;
      // const feecall = await withdrawcontract.changefee(fee)
      // console.log(feecall,'feecall')
      // if(feecall){
      const payload = {
        adminFee: newInputValue,
      };
      let userData = await setAdminFee(payload);
      console.log(userData, "userdata");
      if (userData.status == false) {
        toast.error("Something went wrong from database");
      }
      // console.log("userData",userData);
      toast.success("Executed Successfully");
      // window.location.replace('/dashboard')
      // }
    } catch (error) {
      console.log(error, "errro");
      toast.error("Failed");
    }
  };

  useEffect(() => {
    getAmount();
  }, []);

  return (
    <div className="custom-head">
      <div className="custom-head-sec ">
        <h2 className="custom-title">Set Maximum and Minimum Amounts</h2>
        <div className=" custom-box">
          <div className="d-flex align-items-center">
            <label className="custom-lable me-2" htmlFor="minAmount">
              Minimum Amount:
            </label>
            <input
              className="custom-select"
              type="text"
              id="minAmount"
              placeholder={currentMinAmount}
              onChange={handleMinAmountChange}
              onKeyPress={(event) => {
                const keyCode = event.which || event.keyCode;
                const keyValue = String.fromCharCode(keyCode);
                if (!/^[0-9\b.]+$/.test(keyValue)) {
                  event.preventDefault();
                }
              }}
            />

            {/* <p class="custom-space custom-space-error-content-1" style={{color:"red"}}>{currentMinAmount}  : is your Current Minamount</p> */}
          </div>
          <div className="d-flex align-items-center mt-5">
            <label className="custom-lable me-1" htmlFor="maxAmount">
              Maximum Amount:
            </label>
            <input
              className="custom-select"
              type="text"
              id="maxAmount"
              placeholder={currentMaxAmount}
              onChange={handleMaxAmountChange}
              onKeyPress={(event) => {
                const keyCode = event.which || event.keyCode;
                const keyValue = String.fromCharCode(keyCode);
                if (!/^[0-9\b.]+$/.test(keyValue)) {
                  event.preventDefault();
                }
              }}
            />
            <br />
            {/* <p class="custom-space custom-space-error-content-2 " style={{color:"red"}}>{currentMaxAmount}  : is your Current Maxamount</p> */}
          </div>
          <button
            className="custom-btn custom-child-button mt-5 d-flex justify-content-center align-items-center"
            onClick={Saveamount}
          >
            Save
          </button>
        </div>

        <div>
          <div className="new-button-whole-content">
            <label
              className="custom-lable"
              style={{ color: "black" }}
              htmlFor="newAmount"
            >
              New Amount:
            </label>
            <input
              className="custom-select"
              type="text"
              id="newAmount"
              placeholder="Enter Fee amount"
              value={newInputValue}
              onChange={handleNewInputChange}
              onKeyPress={(event) => {
                const keyCode = event.which || event.keyCode;
                const keyValue = String.fromCharCode(keyCode);
                if (!/^[0-9\b.]+$/.test(keyValue)) {
                  event.preventDefault();
                }
              }}
            />
            <button
              className="custom-btn mx-2 custom-child-button-2"
              onClick={handleAddNewAmount}
            >
              Change Fee Percentage
            </button>
          </div>
        </div>

        <div className="new-button-whole-content">
            <label
              className="custom-lable"
              style={{ color: "black" }}
              htmlFor="newAmount"
            >
              Private Key:
            </label>
            <input
              className="custom-select"
              type="text"
              id="privateKey"
              placeholder="Enter Private key"
              value={newPrivateKeyValue}
              onChange={handleNewPrivateKeyChange}
              // onKeyPress={(event) => {
              //   const keyCode = event.which || event.keyCode;
              //   const keyValue = String.fromCharCode(keyCode);
              //   if (!/^[0-9\b.]+$/.test(keyValue)) {
              //     event.preventDefault();
              //   }
              // }}
            />
            <button
              className="custom-btn mx-2 custom-child-button-2"
              onClick={submitPrivateKey}
            >
              Submit
            </button>
          </div>
      </div>
    </div>
  );
}

export default AmountSettings;
