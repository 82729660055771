import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Login from './pages/login';
import Dashboard from './pages/Dashboard';
import ForgotPassword from './pages/forgetPass';
import ResetPassword from './pages/resetPass';
import ProtectedRoute from "./auth/ProtectedRoute";
import { ToastContainer, toast } from "react-toastify";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Login />} />
          <Route exact path='/forgot-password' element={<ForgotPassword />} />
          <Route exact path='/forgotPass/:token' element={<ResetPassword />} />
          <Route exact path='/dashboard' element={<ProtectedRoute> <Dashboard /> </ProtectedRoute>} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
