import React, { useState } from "react";
import Web3 from "web3";
import config from "../config/config";
import { useEffect } from "react";
import * as ethers from 'ethers'
import abi from './ABI/contractabi.json'
import './Dashboard.css'
import { toast } from "react-toastify";

function WithdrawTokenForm() {
  const [amount, setAmount] = useState("");
  const [message, setMessage] = useState("");
  const [address,setAddress] = useState('')
  const [walletAddress,setWalletAddress]=useState('')

  const switchNetwork = async () => {
    try {
      let result = await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: config.chainID }],
      });
      console.log("result", result);
      window.location.reload();
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          let resultAdd = await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: config.chainID,
                chainName: config.chainName,
                rpcUrls: [config.goerliRpcURL],
                blockExplorerUrls: [config.blockExplorerUrls],
                nativeCurrency: {
                  name: "GoerliETH",
                  symbol: "GoerliETH",
                  decimals: 18,
                },
              },
            ],
          });
          console.log("resultAdd", resultAdd);

          window.location.reload(); // Set the flag to prevent further reloads
        } catch (addError) {
          // handle "add" error
          console.log(addError);
        }
      }
      // handle other "switch" errors
    }
  };

  const walletConnect = async () => {
    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      // console.log("accounts", accounts[0]);
      localStorage.setItem("address", accounts[0]);
      let address = localStorage.getItem("address");
      setAddress(address);
      setWalletAddress(
        address.substring(0, 5) + "..." + address.substring(address.length - 4)
      );
      const chainId = await window.ethereum.request({ method: "eth_chainId" });
      if (chainId != config.chainID) {
        switchNetwork();
      }
    } catch (error) {
      console.log("connectWallet error", error);
    }
  };

  const handleWithdrawal = async (event) => {
    event.preventDefault();

    // You can make an API request to your backend to handle the withdrawal
    // Replace 'yourWithdrawalAPIEndpoint' with the actual API endpoint
    try {
      const web3 = new Web3(window.ethereum);
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const withdrawcontract = new ethers.Contract(
        config.flashLoan_contractAddress,
        abi,
        signer
      );

      const withdrawcall =  await withdrawcontract.recoverTokens(amount)
      console.log('withdrawcall',withdrawcall)
      toast.success("Withdraw Success")
    } catch (error) {
      console.error(error);
      setMessage("An error occurred.");
      toast.error("Transaction Failed")
    }
  };


  useEffect(() => {
    walletConnect()
  
   
  }, [])
  

  return (
    <div className="custom-head">
      <h1 className="custom-title">Withdraw Tokens</h1>
      <form >
        <label className="custom-lable" htmlFor="amount">Enter Token Address to Withdraw :</label>
        <input
        className="custom-select"
          type="text"
          id="amount"
          name="amount"
          step="0.01"
          min="0"
          placeholder="Enter the Token Address to Withdraw"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
        />
        <br></br>
        <button className="custom-btn" onClick={handleWithdrawal}>Withdraw</button>
      </form>
      <p>{message}</p>
    </div>
  );
}

export default WithdrawTokenForm;
